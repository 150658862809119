import React from "react";
import { gsap } from "../vendor/gsap/src/index.js";
import { ScrollTrigger } from "../vendor/gsap/src/ScrollTrigger.js";
import ModalImage from "react-modal-image";
import { isMobileSafari } from 'react-device-detect';

import "./parallaxImage.scss";
gsap.registerPlugin(ScrollTrigger);

class ParallaxImage extends React.Component {
    trigger;
    slug;
    type;
    tl;
    extraClass = '';
    reference;
    isInitialized = false;

    constructor() {
        super();
        ScrollTrigger.config({ limitCallbacks: true });
    }

    componentDidMount() {
        if (this.props.timeline != null && ((this.props.images.parallaxImage1 != null && this.props.images.parallaxImage1 !== '') || (this.props.images.parallaxImage2 != null && this.props.images.parallaxImage2 !== '') || (this.props.images.parallaxImage3 != null && this.props.images.parallaxImage3 !== ''))) {
            this.kill();
            this.init();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.slug === nextProps.slug) {
            return false;
        }

        return true;
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        this.kill();
        // window.removeEventListener('resize', this.reference);
    }

    kill() {
        let scrollTrigger = ScrollTrigger.getById(`${this.props.slug}-parallax`);
        if (scrollTrigger) {
            if (this.tl)
                this.tl.kill();
            scrollTrigger.kill();
        }
    }

    init() {
        this.isInitialized = true;
        this.tl = this.getTimeline(this.props.timeline);
        this.trigger = ScrollTrigger.create({
            id: `${this.props.slug}-parallax`,
            trigger: `.print-ignore .${this.props.slug}-parallax`,
            animation: this.tl,
            start: "top 90%",
            end: "bottom 0%",
            scrub: 1,
            invalidateOnRefresh: true
        });
    }

    getTimeline(timeline) {
        let methodName = `${timeline}Timeline`;
        return (this[methodName]) ? this[methodName]() : this.verticalTimeline();
    }

    crossingTimeline() {
        let tl = gsap.timeline();
        let itemImage = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(1)`);
        if (itemImage) {
            tl.to(itemImage, {
                ease: "power2.inOut",
                css: { left: '20px', bottom: '20px', zoom: 1 }
            }, 'parallax-crossing');
        }

        let itemImage2 = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(2)`);
        if (itemImage2) {
            tl.to(itemImage2, {
                ease: "power2.inOut",
                css: { top: '-20px', zoom: 1 }
            }, 'parallax-crossing');
        }

        let itemImage3 = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(3)`);
        if (itemImage3) {
            tl.to(itemImage3, {
                ease: "power2.inOut",
                css: { right: '20px', bottom: '20px', zoom: 1 }
            }, 'parallax-crossing');
        }

        return tl;
    }

    crossingInverseTimeline() {
        let tl = gsap.timeline();
        let itemImage = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(1)`);
        if (itemImage) {
            tl.to(itemImage, {
                ease: "power2.inOut",
                css: { left: '-20px', bottom: '-20px', zoom: 1 }
            }, 'parallax-crossingInverse');
        }

        let itemImage2 = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(2)`);
        if (itemImage2) {
            tl.to(itemImage2, {
                ease: "power2.inOut",
                css: { top: '20px', zoom: 1 }
            }, 'parallax-crossingInverse');
        }

        let itemImage3 = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(3)`);
        if (itemImage3) {
            tl.to(itemImage3, {
                ease: "power2.inOut",
                css: { right: '-20px', bottom: '-20px', zoom: 1 }
            }, 'parallax-crossingInverse');
        }

        return tl;
    }

    verticalTimeline() {
        let tl = gsap.timeline();
        let itemImage = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(1)`);
        if (itemImage) {
            tl.to(itemImage, {
                ease: "power2.inOut",
                css: { bottom: '50px' }
            }, 'parallax-horizontal');
        }

        let itemImage2 = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(2)`);
        if (itemImage2) {
            tl.to(itemImage2, {
                ease: "power2.inOut",
                css: { top: '0px' }
            }, 'parallax-horizontal');
        }

        let itemImage3 = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(3)`);
        if (itemImage3) {
            tl.to(itemImage3, {
                ease: "power2.inOut",
                css: { bottom: '0px' }
            }, 'parallax-horizontal');
        }

        return tl;
    }

    horizontalTimeline() {
        let tl = gsap.timeline();
        let itemImage = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(1)`);
        let itemImage2 = document.querySelector(`.print-ignore .${this.props.slug}-parallax img:nth-child(2)`);
        if (itemImage) {
            tl.to(itemImage, {
                ease: "power2.inOut",
                css: { left: '50px' }
            }, 'parallax-horizontal');
        }

        if (itemImage2) {
            tl.to(itemImage2, {
                ease: "power2.inOut",
                css: { right: '50px' }
            }, 'parallax-horizontal');
        }

        return tl;
    }

    render() {
        if (this.props.images.parallaxImage1 != null && this.props.images.parallaxImage1 !== '') {
            if (this.props.images.parallaxImage1.indexOf('videos.sproutvideo') > -1) {
                this.type = 'sprout';
            } else if (this.props.images.parallaxImage1.indexOf('media.') > -1) {
                this.type = 'mediaspirit';
            } else {
                this.type = 'random';
            }
        }

        if (this.props.slug.indexOf('-group-page') > -1) {
            this.extraClass = 'group-page-parallax';
        }
        if (this.type === 'mediaspirit') {
            if ((this.props.images.parallaxImage1 != null && this.props.images.parallaxImage1 !== '') || (this.props.images.parallaxImage2 != null && this.props.images.parallaxImage2 !== '') || (this.props.images.parallaxImage3 != null && this.props.images.parallaxImage3 !== '')) {
                if (this.props.clickable != null && this.props.clickable === true) {
                    return (
                        <>
                            <div className={`${this.props.slug}-parallax ${this.extraClass} ${this.props.timeline}-parallax ${this.props.compleet === "true" ? 'compleet-parallax parallax' : 'parallax'} modal-image`}>
                                {(this.props.redirectUrl == null && this.props.images.parallaxImage1 != null) && <ModalImage
                                    small={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage1.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage1}
                                    large={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage1.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage1}
                                    loading="lazy"
                                />}
                                {(this.props.redirectUrl == null && this.props.images.parallaxImage2 != null) && <ModalImage
                                    small={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage2.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage2}
                                    large={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage2.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage2}
                                    loading="lazy"
                                />}
                                {(this.props.redirectUrl == null && this.props.images.parallaxImage3 != null) && <ModalImage
                                    small={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage3.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage3}
                                    large={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage3.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage3}
                                    loading="lazy"
                                />}
                                {(this.props.redirectUrl != null && this.props.images.parallaxImage1 != null) && <a className="redirect-image" href={this.props.redirectUrl} rel="noreferrer" target="_blank">
                                    <img src={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage1.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage1} alt="parallax" />
                                </a>}
                            </div>
                        </>
                    );
                } else {
                    return (
                        <>
                            <div className={`${this.props.slug}-parallax ${this.extraClass} ${this.props.timeline}-parallax ${this.props.compleet === "true" ? 'compleet-parallax parallax' : 'parallax'}`}>
                                {(this.props.images.parallaxImage1 != null && this.props.images.parallaxImage1 !== '') && <img className={`${this.props.displayType}`} alt="parallax1" src={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage1.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage1} loading="lazy" />}
                                {(this.props.images.parallaxImage2 != null && this.props.images.parallaxImage2 !== '') && <img className={`${this.props.displayType}`} alt="parallax2" src={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage2.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage2} loading="lazy" />}
                                {(this.props.images.parallaxImage3 != null && this.props.images.parallaxImage3 !== '') && <img className={`${this.props.displayType}`} alt="parallax3" src={(this.props.safariCheck === true || isMobileSafari === true) ? this.props.images.parallaxImage3.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.images.parallaxImage3} loading="lazy" />}
                            </div>
                        </>
                    );
                }
            }
        } else if (this.type === 'sprout') {
            return (
                <>
                    <div style={{ position: 'relative', height: 0, paddingBottom: '56.25%' }}>
                        <iframe className='sproutvideo-player lazyload' title="module video" src={module.imageUrl} style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }} frameBorder='0' allowFullScreen></iframe>
                    </div>
                </>
            );
        } else if (this.type === 'random') {
            return (
                <>
                    <div>
                        <iframe title="module video" src={module.imageUrl} style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }} frameBorder='0' allowFullScreen></iframe>
                    </div>
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }
}

export default ParallaxImage;
